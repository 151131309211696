import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import firebase from '../Firebase'


export default function LinkRedirection() {
    const [helpShown, setHelpShown] = useState(false);
    const [redirectionLink, setRedirectionLink] = useState(null);

    useEffect(() => {
        const loadTransaction = async () => {
            let transactionId = window.location.href.split("/").pop();
            let transasctionDocRef = firebase.firestore().collection("TRANSACTIONS").doc(transactionId);
            try {
                transasctionDocRef.update({ status: "OPENED" })
                let transasctionDoc = await transasctionDocRef.get()
                let paymentUrl = transasctionDoc.data().link
                setRedirectionLink(paymentUrl)
                window.location.replace(paymentUrl)
                setTimeout(() => { setHelpShown(true) }, 3000)
            }
            catch (error) {
                alert("Failed to load transaction. Check console for more details.")
                console.log(error.message)
            }
        }
        loadTransaction();
    }, [])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center"
        }} >
            <CircularProgress
                color='primary'
                style={{ marginBottom: 24 }}
            />
            <Typography>
                Loading ...
            </Typography>
            {
                helpShown
                &&
                <Typography style={{ marginTop: 24 }}>
                    Click <a href={redirectionLink}>here</a> if the payment page is not showing
                </Typography>
            }
        </div>
    )
}