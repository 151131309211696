import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import firebase from '../Firebase';


export default function ReceiptRedirection() {
    const [helpShown, setHelpShown] = useState(false);
    const [redirectionLink, setRedirectionLink] = useState(null);

    useEffect(() => {
        const loadReceipt = async () => {
            let transactionId = window.location.href.split("/").pop();
            try {
                let receiptDownloadUrl = await firebase.storage()
                    .ref("receipts/" + transactionId + ".pdf")
                    .getDownloadURL()
                setRedirectionLink(receiptDownloadUrl)
                window.location.replace(receiptDownloadUrl)
                setTimeout(() => { setHelpShown(true) }, 3000)

            }
            catch (error) {
                alert("Failed to load transaction. Check console for more details.")
                console.log(error.message)
            }
        }
        loadReceipt();
    }, [])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center"
        }} >
            <CircularProgress
                color='primary'
                style={{ marginBottom: 24 }}
            />
            <Typography>
                Loading ...
            </Typography>
            {
                helpShown
                &&
                <Typography style={{ marginTop: 24 }}>
                    Click <a href={redirectionLink}>here</a> if the receipt is not showing
                </Typography>
            }
        </div>
    )
}