import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

function CardForm() {
    const [isLoading, setIsLoading] = useState(true)

    const fieldsStyle = {
        height: 40,
        margin: 5,
        borderRadius: 5,
        paddingLeft: 20,
        width: "100%",
        backgroundColor: "#f2f2f2"
    }

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#003366',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '25px',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: 600,
        width: '100%',
        height: '45px',
        textDecoration: 'none'
    };

    useEffect(() => {
        const initHostedSession = async () => {
            let url = window.location.href.split("/")
            let orderId = url.pop();
            let sessionId = url.pop();

            window.PaymentSession.configure({
                session: sessionId,
                fields: {
                    card: {
                        number: "#card-number",
                        securityCode: "#security-code",
                        expiryMonth: "#expiry-month",
                        expiryYear: "#expiry-year",
                        nameOnCard: "#cardholder-name"
                    }
                },
                frameEmbeddingMitigation: ["javascript"],
                callbacks: {
                    initialized: function (response) {
                        window.ThreeDS.configure({
                            merchantId: "TEST011997",
                            sessionId: sessionId,
                            containerId: "3DSUI",
                            callback: function () {
                                if (window.ThreeDS.isConfigured()) {
                                    console.log("Done with configure: " + window.ThreeDS.isConfigured());
                                    setIsLoading(false)
                                }
                                else {
                                    window.location.replace("https://etapay.me/loading/failed");
                                }
                            },
                            configuration: {
                                userLanguage: "en-US", //Optional parameter
                                wsVersion: 81
                            }
                        });
                    },
                    formSessionUpdate: function (response) {
                        setIsLoading(true);
                        window.ThreeDS.initiateAuthentication(orderId, "1", function (data) {
                            if (data && data.error) {
                                var error = data.error;
                                console.log("error.code : ", error);
                                window.location.replace("https://etapay.me/loading/failed");
                            }
                            else {
                                document.getElementById('3DSUI').innerHTML = data.htmlRedirectCode
                                setTimeout(() => {
                                    window.ThreeDS.authenticatePayer(orderId, "1", function (authenticatePayerData) {
                                        if (!authenticatePayerData.error) {
                                            console.log("[Authenticate Payer] REST API response ", authenticatePayerData.restApiResponse);
                                        }
                                        else {
                                            console.log("EMV 3DS ERROR - " + authenticatePayerData.error)
                                            window.location.replace("https://etapay.me/loading/failed");
                                        }
                                    }, { fullScreenRedirect: true });
                                }, 5000);
                            }
                        });
                    }
                },
                interaction: {
                    displayControl: {
                        formatCard: "EMBOSSED",
                        invalidFieldCharacters: "REJECT"
                    }
                }
            });
        }

        initHostedSession();
    }, [])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            backgroundColor: "#f2f2f2",
            height: "100%"
        }}>
            <Typography style={{ margin: 8, fontSize: 12 }} variant="footnote">
                Please enter your card information. We will verify the card by making a small charge and then promptly refunding it.
            </Typography>
            <input
                id="card-number"
                placeholder='Card number'
                readOnly={true}
                style={fieldsStyle}
            />
            <input
                id="expiry-month"
                placeholder='Expiry month (MM)'
                readOnly={true}
                style={fieldsStyle}
            />
            <input
                id="expiry-year"
                placeholder='Expiry year (YY)'
                readOnly={true}
                style={fieldsStyle}
            />
            <input
                id="security-code"
                placeholder='CSC/CVC'
                readOnly={true}
                style={fieldsStyle}
            />
            <input
                id="cardholder-name"
                placeholder='Cardholder name'
                readOnly={true}
                style={fieldsStyle}
            />
            <br />
            {
                isLoading
                    ?
                    <CircularProgress
                        color='primary'
                        style={{ marginBottom: 24, alignSelf: "center" }}
                    />
                    :
                    <button style={buttonStyle}
                        onClick={() => { window.PaymentSession.updateSessionFromForm('card'); }}>
                        Add card
                    </button>
            }

            <div id="3DSUI"></div>
        </div>
    )
}

export default CardForm;