import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

// Your web app's Firebase configuration
const Firebase = firebase.initializeApp({
    apiKey: "AIzaSyCRKnW4gy6Z674SBJq1S3st4sBhcRObxQo",
    authDomain: "etapay-app.firebaseapp.com",
    projectId: "etapay-app",
    storageBucket: "etapay-app.appspot.com",
    messagingSenderId: "66716748073",
    appId: "1:66716748073:web:9e3d21fc747adaa20e4f38"
});

export default Firebase;