import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


export default function LoadingRedirection() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center"
        }} >
            <CircularProgress
                color='primary'
                style={{ marginBottom: 24 }}
            />
        </div>
    )
}