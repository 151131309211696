import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';


import LinkRedirection from './redirection/LinkRedirection';
import ReceiptRedirection from './redirection/ReceiptRedirection';
import CardForm from './redirection/CardForm';
import LoadingRedirection from './redirection/LoadingRedirection';
import Home from './Home'

const theme = createTheme({
  palette: {
    primary: {
      main: '#003366',
    },
    secondary: {
      main: '#5DBFD4'
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/l/:transactionId" element={<LinkRedirection />} />
          <Route path="/r/:transactionId" element={<ReceiptRedirection />} />
          <Route path="/cardform/:sessionId/:orderId" element={<CardForm />} />
          <Route path="/loading/:status" element={<LoadingRedirection />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);