import React from 'react';
import { Typography } from '@mui/material';
import LogoWithCompanyName from './assets/etapay-khwarizm.png'

export default function Home() {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', margin: 0, padding: 0, backgroundColor: '#f4f4f4', color: '#333' }}>
            <header style={{ display: "flex", height: "8vh", justifyContent: "center", alignItems: "center" }}>
                <img src={LogoWithCompanyName} style={{ height: "100%", width: "auto", objectFit: "contain", marginTop: 20 }} />
            </header>
            <div style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', background: 'white', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <section style={{ marginBottom: '20px' }}>
                    <h2 style={{ color: '#5DBFD4', fontSize: '1.5em' }}>Introducing etaPay</h2>
                    <p>Connect to your payment services provider and start accepting payments instantly without development and at lower cost, in person via QR or online via links.</p>
                </section>
                <section style={{ marginBottom: '20px' }}>
                    <h2 style={{ color: '#5DBFD4', fontSize: '1.5em' }}>How it works:</h2>
                    <ol>
                        <li><span role="img" aria-label="download">📥</span> Download etaPay and create your account</li>
                        <li><span role="img" aria-label="lock">🔒</span> Subscribe to enable live payments mode</li>
                        <li><span role="img" aria-label="settings">⚙️</span> Select your payment services provider and enter setup details</li>
                        <li><span role="img" aria-label="credit card">💳</span> Start accepting payments using QR in person or by sending links online</li>
                    </ol>
                </section>
                <section style={{ marginBottom: '20px' }}>
                    <h2 style={{ color: '#5DBFD4', fontSize: '1.5em' }}>Features:</h2>
                    <ul style={{ paddingLeft: '20px' }}>
                        <li><span role="img" aria-label="timer">⏱️</span> Easy setup, go live in minutes</li>
                        <li><span role="img" aria-label="phone">📱</span> In-person acceptance with QR</li>
                        <li><span role="img" aria-label="link">🔗</span> Online acceptance with links</li>
                        <li><span role="img" aria-label="receipt">🧾</span> Generate receipts for your customers</li>
                        <li><span role="img" aria-label="chart">📊</span> View reports, and check the status of each payment</li>
                        <li><span role="img" aria-label="folder">📂</span> Export transaction data</li>
                        <li><span role="img" aria-label="paint">🎨</span> Customize your payment page with your own logo and details</li>
                        <li><span role="img" aria-label="team">👥</span> Share app access with your team</li>
                    </ul>
                </section>
                <section>
                    <h2 style={{ color: '#5DBFD4', fontSize: '1.5em' }}>Coming Soon</h2>
                    <p>Available for iOS and Android in 2025.</p>
                </section>
            </div>
            <footer style={{ textAlign: 'center', padding: '10px', backgroundColor: '#333', color: 'white', marginTop: '20px' }}>
                <p>2024 KHWARIZM TECHNOLOGY COMPANY LLC</p>
                <p>Jeddah, Saudi Arabia</p>
                <p>Contact us: <a href="mailto:support@etapay.me" style={{ color: '#5DBFD4', textDecoration: 'none' }}>support@etapay.me</a></p>
            </footer>
        </div>
    );
}